import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";

interface Props {
  data: any;
  start_no: number;
  isPurchase?: boolean;
}

const PDFTable = ({ data, start_no, isPurchase }: Props) => {
  const header = [
    {
      label: "No.",
      thaiLabel: " ",
      width: 20,
    },
    {
      label: "Name",
      thaiLabel: "รายการ",
      width: 260,
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: 40,
    },
    {
      label: "Price/Unit",
      thaiLabel: "ราคา/หน่วย",
      width: 65,
    },
    {
      label: "Discount",
      thaiLabel: "ส่วนลด",
      width: 50,
    },
    {
      label: "Vat",
      thaiLabel: "ภาษี",
      width: 40,
    },
    {
      label: "Pre-vat Amount",
      thaiLabel: "จำนวนเงิน",
      width: 65,
    },
  ];
  return (
    <TableContainer sx={{ minHeight: "480px", maxHeight: "850px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                align={head.label === "Name" ? "left" : "right"}
                key={index}
                sx={{
                  pr: head.label === "Name" ? "" : 0,
                  pl: head.label === "Name" ? 1 : "",
                  py: 1,
                  backgroundColor: isPurchase ? "#F2EEFC" : "#ECF6FD",
                  borderTopLeftRadius: index === 0 ? 4 : 0,
                  borderBottomLeftRadius: index === 0 ? 4 : 0,
                  borderTopRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottomRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottom: "none",
                }}
              >
                <Typography width={head.width} fontSize={11} fontWeight={600}>
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <TableRow
                key={item.item_unique_id}
                sx={{
                  breakInside: "avoid",
                  height: "50px",
                  verticalAlign: "top",
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>{start_no + index}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    py: 1,
                    px: 0.25,
                    maxWidth: "295px",
                  }}
                >
                  <Typography
                    fontSize={11}
                    fontWeight={600}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {item.item_unique_id} - {item.item_name}
                  </Typography>
                  <Typography
                    fontSize={11}
                    sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                  >
                    {item.item_sku_desc}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>{item.qty}</Typography>
                  <Typography fontSize={9} color="#737373">
                    {item.uom}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.price_per_unit
                      ? formatNumber(item.price_per_unit)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.discount
                      ? formatNumber(item.discount)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.vat_percentage !== "ไม่มี"
                      ? item.vat_percentage + "%"
                      : "ไม่ระบุ"}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.pre_vat_amount
                      ? formatNumber(item.pre_vat_amount)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PDFTable;
