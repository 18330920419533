import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePurchaseItemListHeader = (
  documentType: string,
  disabled: boolean
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    };

    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: documentType === "purchase_request" ? 100 : 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: documentType === "purchase_request" ? 400 : 350,
      },
      {
        thaiLabel: documentType === "purchase_return" ? "จำนวนคืน" : "จำนวน",
        label: documentType === "purchase_return" ? "Return Qty" : "Qty",
        width: documentType === "purchase_request" ? 152 : 100,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: documentType === "purchase_request" ? 170 : 100,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
      },

      {
        thaiLabel: "ภาษี",
        label: "Vat",
        width: 100,
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
      },
      {
        thaiLabel: "หัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
      },
      {
        thaiLabel: "หมายเหตุสินค้า",
        label: "Product Remark",
        width: documentType === "purchase_request" ? 250 : 150,
      },
    ];

    if (!disabled) {
      if (documentType === "purchase_request") {
        const purchaseRequestHeaders = [...defaultHeaders];
        purchaseRequestHeaders.splice(4, 6);
        purchaseRequestHeaders.splice(4, 0, {
          thaiLabel: "สั่งซื้อแล้ว",
          label: "PO Qty",
          width: 170,
        });
        setHeaders([
          ...purchaseRequestHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else if (documentType === "purchase_order") {
        const purchaseOrderHeaders = [...defaultHeaders];
        purchaseOrderHeaders.splice(10, 0, {
          thaiLabel: "ใบขอซื้อ",
          label: "PR Ref",
          width: 150,
        });
        setHeaders([
          ...purchaseOrderHeaders,
          {
            thaiLabel: "จำนวนนำเข้า",
            label: "Received Qty",
            width: 100,
          },
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        const purchaseReturnHeaders = [...defaultHeaders];
        purchaseReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนสั่งซื้อ",
          label: "PO Qty",
          width: 100,
        });
        arrayMove(purchaseReturnHeaders, 10, 5);
        // purchaseReturnHeaders.splice(11, 0, {
        //   thaiLabel: "ใบขอซื้อ",
        //   label: "PR Ref",
        //   width: 150,
        // });
        setHeaders([
          ...purchaseReturnHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (documentType === "purchase_request") {
        const purchaseRequestHeaders = [...defaultHeaders];
        purchaseRequestHeaders.splice(4, 6);
        purchaseRequestHeaders.splice(4, 0, {
          thaiLabel: "สั่งซื้อแล้ว",
          label: "PO Qty",
          width: 100,
        });
        setHeaders(purchaseRequestHeaders);
      } else if (documentType === "purchase_order") {
        const purchaseOrderHeaders = [...defaultHeaders];
        purchaseOrderHeaders.splice(10, 0, {
          thaiLabel: "ใบขอซื้อ",
          label: "PR Ref",
          width: 150,
        });
        setHeaders([
          ...purchaseOrderHeaders,
          {
            thaiLabel: "จำนวนนำเข้า",
            label: "Received Qty",
            width: 100,
          },
        ]);
      } else {
        const purchaseReturnHeaders = [...defaultHeaders];
        purchaseReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนสั่งซื้อ",
          label: "PO Qty",
          width: 100,
        });
        arrayMove(purchaseReturnHeaders, 10, 5);
        // purchaseReturnHeaders.splice(11, 0, {
        //   thaiLabel: "ใบขอซื้อ",
        //   label: "PR Ref",
        //   width: 150,
        // });
        setHeaders(purchaseReturnHeaders);
      }
    }
  }, [disabled, documentType]);

  return headers;
};
