import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const useDeliveryOrderItemListHeader = (disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 350,
      },
      {
        thaiLabel: "จำนวน",
        label: "Qty",
        width: 100,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
      },

      {
        thaiLabel: "ภาษี",
        label: "Vat",
        width: 100,
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
      },
      {
        thaiLabel: "หัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
      },
      {
        thaiLabel: "หมายเหตุสินค้า",
        label: "Product Remark",
        width: 150,
      },
    ];

    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled]);

  return headers;
};
